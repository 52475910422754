import React from "react"
import { Link } from "gatsby"

const ContactPage = () => (
    <main>
      <h1>Contact</h1>

      <Link to = "/about">Automatic deployment is de max</Link>
    </main>
)

export default ContactPage;
